<template>
    <div class="mb-5 align-items-center">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="semester_id" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('semester')">
                                <semesters-selectbox :validate-error="errors[0]" v-model="form.semester_id"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('type')">
                                <parameter-selectbox code="semester_dates" :validate-error="errors[0]"
                                                     v-model="form.type"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('start_date')">
                                <select-date :is-time="true" v-model="form.start_date" :validation-error="errors[0]" :time="true"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('end_date')">
                                <select-date :is-time="true" v-model="form.end_date" :validation-error="errors[0]" :time="true"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="level" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('level')">
                                <multi-parameter-selectbox code="program_levels"
                                                           :multiple="true"
                                                           :validate-error="errors[0]"
                                                           v-model="form.level"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="faculty" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox-multiple :validate-error="errors[0]" v-model="form.faculty"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        <div class="col-12 mt-3 d-flex p-0">
            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>

import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox.vue";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import MultiParameterSelectbox from "@/components/interactive-fields/MultiParameterSelectbox.vue";
import FacultySelectboxMultiple from "@/components/interactive-fields/FacultySelectboxMultiple.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

import {ValidationObserver, ValidationProvider} from "vee-validate";

import SemesterDateService from "@/services/SemesterDateService";

export default{
    components:{

        ValidationProvider,
        ValidationObserver,

        SemestersSelectbox,
        ParameterSelectbox,
        MultiParameterSelectbox,
        FacultySelectboxMultiple,
        SelectDate,
    },
    data(){
        return{
            form: {},
        }
    },
    methods: {
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                let formData = {...this.form};

                    SemesterDateService.store(formData)
                        .then((response) => {
                            this.$emit('success')
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error,this.$refs.storeForm)
                        })
            }
        }
    }
}
</script>
